<template>
	<div class="container">
		<div class="head">
			<div class="left">合约信息</div>
			<div class="right">
				<div class="btn_box">
					<a-button class="btn" type="primary" v-if="info.status == 0" @click="showOpModal('withdrawContract')">撤销合约</a-button>
					<a-button class="btn" type="primary" v-if="info.status == 1" @click="showOpModal('endContract')">终止合约</a-button>
					<a-button class="btn" type="primary" v-if="info.status == 2 || info.status == 3" @click="showOpModal('recoveryContract')">恢复合约</a-button>
				</div>
			</div><!-- right -->
		</div><!-- head -->
		<div class="content">
			<div class="item">
				<div class="left">合约状态：</div>
				<div class="right">{{info.stataus_name}}</div>
			</div><!-- item -->
			<div class="item" v-if="info.operation.length">
				<div class="left">合约轨迹：</div>
				<div class="right">
					<div class="time_line">
						<a-timeline>
							<a-timeline-item :color="status_color(item.type)" v-for="(item,index) in info.operation">
								<div class="title">{{item.title}}</div>
								<div class="desc">{{'时间：' + item.operator_time}}</div>
								<div class="desc">{{'操作人：' + item.operator_name}}</div>
								<div class="desc" v-if="item.reason">{{'终止原因：' + item.reason}}</div>
							</a-timeline-item>
						</a-timeline>
					</div>
				</div>
			</div><!-- item -->
			<div class="item">
				<div class="left">投放规则：</div>
				<div class="right">
					<div class="week">{{info.week}}</div>
				</div>
			</div><!-- item -->
			<div class="item">
				<div class="left">关联投放记录：</div>
				<div class="right">
					<a @click="toList('put')">{{info.launch_num}}</a>
				</div>
			</div><!-- item -->
			<div class="item">
				<div class="left">关联发放记录：</div>
				<div class="right">
					<a @click="toList('grant')">{{info.grant_num}}</a>
				</div>
			</div><!-- item -->
		</div><!-- content -->
		<a-modal method="success" v-model:visible="visible" :title="apiName == 'endContract' ? '您确定要执行本次操作吗？' : false" @ok="operateContract">
			<a-input v-if="apiName == 'endContract'" class="reason_input" placeholder="请输入终止原因" v-model:value="op_form.reason"></a-input><!-- 终止合约需要填写原因 -->
			<div v-else>您确定要执行本次操作吗？</div>
		</a-modal>
	</div><!-- container -->
</template>

<script>
	import { Modal } from 'ant-design-vue';
	import {withdrawContract,endContract,recoveryContract} from '@/api/cost.js';
	export default {
		props: {
			info: {
				type: Object,
				default(){
					return {}
				}
			},
		},
		components: {
			
		},
		data() {
			return {
				withdrawContract,
				endContract,
				recoveryContract,
				visible:false, // 合约操作弹窗是否显示
				apiName:'', // 点击合约操作按钮时会将当前按钮要执行的接口方法名记录在此变量中
				op_form:{
					reason:'',
				},// 合约操作框中的表单值
			}
		},
		created() {
			
		},
		unmounted() {},
		methods: {
			/**
			 * 显示操作对话框
			 * @param {String} apiName 用来操作合约的接口方法名
			 */
			showOpModal(apiName){
				this.apiName = apiName;
				this.visible = true;
			},
			operateContract(){
				this[this.apiName]({
					data:{
						id:this.info.id,
						...this.op_form
					},
					loading:true
				}).then(res=>{
					this.$emit('after_operate');
					this.visible = false;
				})
			},
			toList(path){
				this.$router.push({
				    path,
				    query: {
				        contract_id:this.info.id
				    }
				})
			},
		},
		computed: {
			status_color() {
				return function(type){
					if(type == 'expire_contract'){
						return '#6a6a6a'
					}else if(type == 'create_contract'){
						return '#1890ff'
					}else if(type == 'withdraw_contract'){
						return '#f5222d'
					}else if(type == 'end_contract'){
						return '#f5222d'
					}else if(type == 'recovery_contract'){
						return '#1890ff'
					}
				}
			}
		},
	}
</script>

<style lang="less" scoped>
	@import "../../../../assets/less/app.less";
	.container{
		border:1px solid #f0f0f0;
		box-sizing: border-box;
		margin: 20px;
		margin-bottom: 28px;
		padding: 20px;
		.head{
			display: flex;
			justify-content: space-between;
			margin-bottom: 12px;
			.left{
				font-weight: bold;
				font-size: 22px;
			}
			.right{
				.btn_box{
					.btn{
						padding: 0 10px;
					}
				}
			}
		}
		.content{
			.item{
				display: flex;
				margin-bottom: 12px;
				.left{
					min-width: 75px;
				}
				.right{
					.time_line{
						margin-top: 4px;
						.title{
							font-weight: bold;
						}
						.desc{
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.reason_input{
		box-sizing: border-box;
		padding-left: 10px;
	}
	
</style>
