<template>
    <div class="page_content">
		<Info @after_operate="get_info" :info.sync="info"></Info>
        <div class="page_edit_content page_detail">
            <div class="title_h1">
                {{ info.title }}
            </div>
            <a-descriptions :column="2">
                <a-descriptions-item :span="2" label="客户编号">
                    {{ info.customer_id }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="甲方">
                    {{ info.party_a }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="乙方">
                    {{ info.party_b.name }}
                    {{ info.party_b.address }}
                </a-descriptions-item>
            </a-descriptions>

            <!-- <div class="content" v-html="content"></div> -->

            <div v-for="(vo,index) in content" :key="index">
                <p v-if="vo.type == 'html'" v-html="vo.content"></p>
                <a-table v-else-if="vo.type == 'decompose' && decompose.length"
                    class="mt10"
                    id="table"
                    :columns="columns"
                    :pagination="false"
                    :dataSource="decompose"
                >
                </a-table>
                <div v-else-if="vo.type == 'goods'" v-for="(item,index) in info.goods_data" :key="index">
                    <a-descriptions :column="2">
                        <a-descriptions-item :span="2" :label="(item.type == 'region' ? '位置' : item.type == 'sales' ? '销量' : '数量') + '要求'">
                            {{ item.goods_title }}
                            <span v-if="item.num"> x{{ item.num }}</span>
                            {{ item.require }}
                        </a-descriptions-item>
                    </a-descriptions>
                    <!-- <a-descriptions :column="2">
                        <a-descriptions-item :span="2" label="销售要求">
                            {{ item.num }} {{ item.sales }}
                        </a-descriptions-item>
                    </a-descriptions> -->
                </div>
                <div v-else-if="vo.type == 'frozen_goods'" v-for="(item,k) in info.goods_data" :key="k">
                    <a-descriptions :column="2">
                        <a-descriptions-item :span="2" :label="(item.type == 'region' ? '位置' : item.type == 'sales' ? '销量' : '数量') + '要求'">
                            {{ item.goods_title }} 
                            冰机从上到下第{{item.layers}}层{{item.integer}},共{{item.surface}}个排面 
                            <div v-if="item.type == 'sales' || item.type == 'number' ">x{{item.num}}</div>
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
            </div>

            <a-descriptions :column="2">
                <a-descriptions-item label="甲方代表">
                    <div style="display: flex; flex-direction: column">
                        {{ info.username }}
                        <LookImages
                            :is_img="true"
                            :style_img="{ width: 'auto' }"
                            :list="[info.user_sign]"
                        ></LookImages>
                    </div>
                </a-descriptions-item>
                <a-descriptions-item label="乙方代表">
                    <div style="display: flex; flex-direction: column">
                        {{ info.linkman_name }}
                        <LookImages
                            :is_img="true"
                            :style_img="{ width: 'auto' }"
                            :list="[info.linkman_sign]"
                        ></LookImages>
                    </div>
                </a-descriptions-item>
                <a-descriptions-item label="联系电话">
                    {{ info.phone }}
                </a-descriptions-item>
                <a-descriptions-item label="联系电话">
                    {{ info.linkman_phone }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="监督人">
                    {{ info.custodian_name || "空" }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="监督电话">
                    {{ info.custodian_phone || "空" }}
                </a-descriptions-item>
            </a-descriptions>
        </div>
    </div>
</template>

<script>
import LookImages from "@/components/LookImages";
import { contractDetail } from '@/api/cost'
import Avatar from "@/components/small/Avatar";
import Info from './components/Info.vue'; // 合约信息

export default {
    components: {
		Info,
        LookImages,
        Avatar
    },
    data() {
        return {
            info: {
                party_b: {},
                goods_data: [],
				operation:[],
            },
            columns: [],
            decompose: []
        }
    },
    computed: {
        content() {
            let html = [
                {
                    type: 'html',
                    content: this.info.template_content
                }
            ];
            let template_instruction = ['goods', 'decompose', 'date', 'number', 'reward', 'remark', 'type_name', 'month','frozen_goods','goods_','goods_require','grant_form','region', 'other_reward','supply_']
            template_instruction.forEach(item => {
                html.forEach((element, index) => {
                    if (element.type == 'html' && element.content) {
                        if (item == 'goods' || item == 'decompose' || item == 'frozen_goods') {
                            let ele_arr = element.content.split(`--${item}--`)
                            if (ele_arr.length > 1) {
                                html.splice(index, 1)
                                ele_arr.map((res, key) => {
                                    if (key == 1) {
                                        html.push({
                                            type: item,
                                        })
                                    }
                                    html.push({
                                        type: 'html',
                                        content: res
                                    })
                                    return
                                })
                            }
                        } else {
                            if (element.type == 'html') {
                                if(item == 'goods_'){
                                    if(html[index].content.indexOf('goods_') > -1){
                                        let minimum_require = JSON.parse(this.info.minimum_require)
                                        Object.keys(minimum_require).map(key=>{
                                            html[index].content = html[index].content.replace(`--${key}--`, `<span style="color:#000; border-bottom: 1px solid #000; font-weight: bold;">${minimum_require[key]}</span>`)
                                        })
                                    }
                                }else if(item == 'supply_'){
                                    if(html[index].content.indexOf('supply_') > -1){
                                        let minimum_require = JSON.parse(this.info.minimum_require)
                                        Object.keys(minimum_require).map(key=>{
                                            html[index].content = html[index].content.replace(`--${key}--`, `<span style="color:#000; border-bottom: 1px solid #000; font-weight: bold;">${minimum_require[key]}</span>`)
                                        })
                                    }
                                }else{
                                    let str = this.info[item];
                                    if (item == 'remark') {
                                        str = this.info.remark || '无';
                                    }else if(item == 'goods_require'){
                                        str = this.info.goods_require || '无';
                                    }else if(item == 'grant_form'){
                                        str = this.info.grant_form || '无';
                                    }else if(item == 'region'){
                                        str = this.info.region || '无';
                                    }else if(item == 'other_reward'){
                                        str = this.info.other_reward || '无';
                                    } else if (item == 'date') {
                                        str = this.info.start_time + ' 至 ' + this.info.end_time;
                                    } else if (item == 'number') {
                                        str = this.info.series_num;
                                    } else if (item == 'reward') {
                                        str = ''
                                        this.info.reward.forEach((re, re_index) => {
                                            str += re.goods_title + 'x' + re.num + '件/月'
                                            if (this.info.reward.length - 1 != re_index) {
                                                str += ','
                                            }
                                        });
                                        if (this.info.cash) {
                                            str += ", 现金" + this.info.cash + '元'
                                        }
                                    }
                                    html[index].content = html[index].content.replace(`--${item}--`, `<span style="color:#000; border-bottom: 1px solid #000; font-weight: bold;">${str}</span>`)
                                }
                            }
                        }
                    }
                })

            })

            return html
        }
    },
    created() {
        this.get_info()
        this.$nextTick(() => {
            setTimeout(() => {
                
            }, 5000)
        })
    },
    methods: {
        get_info() {
            contractDetail({
                data: {
                    id: this.$route.query.id,
                }
            }).then(res => {
                this.info = res.data.data

                const columns = [];

                let len = res.data.data.decompose.length / res.data.data.goods_data.length
                let list = res.data.data.decompose;
                let goods_list = [];
                let goods = {}

                for (let i = 0; i < list.length; i++) {
                    if (i < len) {
                        if (i == 0) {
                            columns.push({
                                title: "品类",
                                dataIndex: "goods_title",
                            })
                            columns.push({
                                title: list[i].date,
                                dataIndex: "goods" + i,
                            })
                        } else {
                            columns.push({
                                title: list[i].date,
                                dataIndex: "goods" + i,
                            })
                        }
                    }
                    if (goods[list[i].goods_id]) {
                        goods[list[i].goods_id]['goods' + (i % len)] = list[i].sales
                    } else {
                        goods[list[i].goods_id] = {
                            goods_title: list[i].goods_title,
                            goods0: list[i].sales
                        }
                        // goods[list[i].goods_id] = {

                        // }
                    }
                }

                for (const key in goods) {
                    goods_list.push(goods[key])
                }

                this.columns = columns
                this.decompose = goods_list;
            })
        }
    }
}
</script>

<style lang="less" scoped>
.title_h1 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding: 30px 0 50px;
}
.page_detail{
	padding: 20px;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.card{
    margin-bottom: 10px;
    .goods_box{
        .goods_group{
            margin-bottom: 12px;
            .goods_item{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: #333333;
                margin-bottom: 8px;
                line-height: 25px;
            
                .right{
                    min-width: 40px;
                    text-align: right;
                    border-left: 1px solid #ededef;
                    
                }
                .left{
                    display: flex;
                    .title{
                        font-weight: bold;
                        font-size: 15px;
                        white-space: nowrap;
                    }
                    .content{
                        .goods{
                            padding-right: 8px;
                            
                        }
                        .desc{
                            color: #888888;
                        }
                    }
                }
            }
        }
    }
}
</style>